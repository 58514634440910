import React, { useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import copyIcon from '../../assets/img/copy.svg';
import { signupUser } from '../../services/users';

export default function UserRegistration() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [copiedClass, setCopiedClass] = useState('');
  const [userCreatedClass, setUserCreatedClass] = useState('');
  const { t } = useTranslation();

  function copyPass() {
    setCopiedClass('copied-successful');
    const content = document.getElementById('password');
    content.select();
    document.execCommand('copy');
  }
  function newUser() {
    return signupUser(email, firstName, lastName)
      .then((data) => {
        if (data.error) {
          setError(true);
        } else {
          setPassword(data.password);
          setUserCreatedClass('user-created-successful');
        }
      });
  }
  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{t('userRegistration_error')}</p>
      );
    }
    return null;
  }
  return (
    <div className="user-registration">
      <div className="content-gral">
        <h2 className="title-page">{t('userRegistration_title')}</h2>
        <div className={`content-form ${userCreatedClass}`}>
          <form>
            <div className="line-form">
              <span>{t('userRegistration_name')}</span>
              <input
                type="text"
                placeholder={t('userRegistration_placeholderName')}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="line-form">
              <span>{t('userRegistration_lastName')}</span>
              <input
                type="text"
                placeholder={t('userRegistration_placeholderLastName')}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </div>
            <div className="line-form">
              <span>E-mail</span>
              <input
                type="email"
                placeholder={t('userRegistration_placeholderMail')}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            {renderMsgError()}
            <button type="button" className="btn-gral" onClick={() => newUser()}>{t('userRegistration_btn')}</button>
          </form>
          <div className="generated-password-box">
            <p>
              {t('userRegistration_passwordGenerated1')}
              {` ${email} `}
              {t('userRegistration_passwordGenerated2')}
            </p>
            <div className="copy-box">
              <input type="text" className="password" value={password} id="password" />
              <button type="button" title="Copiar" onClick={() => copyPass()}><img alt="copy" src={copyIcon} /></button>
              <span className={`copied ${copiedClass}`}>{t('userRegistration_copied')}</span>
            </div>
            <span className="msg">{t('userRegistration_msg')}</span>
            <a href="/" className={`btn-gral ${copiedClass}`}>{t('userRegistration_accept')}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
