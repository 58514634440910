import React from 'react';
import './styles.scss';
import Header from '../header';
import Footer from '../footer';

export default function NavBar({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}
