import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/loader';
import { useAuth } from '../../hooks/auth';
import { getBalance } from '../../services/balance';
import upload from '../../assets/img/upload.svg';
import download from '../../assets/img/download.svg';
import ResumeCard from '../../components/resume-card';
import FundCard from '../../components/card-fund';
import CoinCard from '../../components/card-coin';
import { getCoins } from '../../services/coins';

const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;

export default function Resume() {
  const { getDecodedToken } = useAuth();
  const { firstName, email } = getDecodedToken();
  const [liquido, setLiquido] = useState();
  const [rentaFija, setRentaFija] = useState();
  const [altoRendimiento, setAltoRendimiento] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [coin, setCoin] = useState([]);

  const Messageto = ({
    messageTo, children, className,
  }) => {
    return <a className={className} target="blank" href={`https://wa.me/+549${PHONE_NUMBER}?text=${messageTo}`}>{children}</a>;
  };

  useEffect(() => {
    setLoading(true);
    return getBalance()
      .then((data) => {
        setLiquido(data.liquido);
        setRentaFija(data.rentafija);
        setAltoRendimiento(data.altorendimiento);
        setLoading(false);
        if (data.error) {
          setError(true);
          setLoading(false);
        }
      });
  }, []);

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">
          {t('resume_error')}

        </p>
      );
    }
    return null;
  }

  useEffect(() => {
    getCoins()
      .then((data) => {
        setCoin(data);
      });
  }, []);

  function renderCards() {
    const cards = [];
    coin.forEach((element) => {
      cards.push((
        <CoinCard name={element.name} price={element.price} symbol={element.symbol} />
      ));
    });
    return cards;
  }

  return (
    <div className="resume-page">
      <div className="content-gral">
        <h2 className="title">
          <p>{t('resume_title')}</p>
          <p className="buttons">
            <Messageto className="btn-gral blue" messageTo={`Buen día, soy ${firstName} (${email}) y quiero rescatar al fondo ... con $...`}>
              <img alt="" src={upload} />
              <span>
                {t('resume_btn1')}
              </span>
            </Messageto>
            <Messageto className="btn-gral" messageTo={`Buen día, soy ${firstName} (${email}) y quiero suscribirme al fondo ... con $...`}>
              <img alt="" src={download} />
              {t('resume_btn2')}
            </Messageto>
          </p>
        </h2>
        {msgError()}

        {
          loading ? <Loader />
            : (
              <div className="cards-funds">
                <ResumeCard type="general" fund="general" />
                <div className="stats-funds">
                  {
          liquido && rentaFija && altoRendimiento
            ? (
              <>
                <FundCard fund={liquido} fundType="liquido" />
                <FundCard fund={rentaFija} fundType="rentafija" />
                <FundCard fund={altoRendimiento} fundType="altorendimiento" />
              </>
            )
            : (<> </>)
        }
                </div>
                <div className="coins">
                  <h2>{t('coin_title')}</h2>
                  <div className="grid-coins">
                    {renderCards()}
                  </div>
                </div>
              </div>
            )
        }

      </div>
    </div>
  );
}
