import React from 'react';
import '../styles.scss';
import { useTranslation } from 'react-i18next';
import upload from '../../../assets/img/upload.svg';
import { useAuth } from '../../../hooks/auth';
import download from '../../../assets/img/download.svg';
import ResumeCard from '../../../components/resume-card/index';
import Movements from '../../../components/movements/index';

const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER;

export default function ResumeFunds() {
  const { t } = useTranslation();
  const { getDecodedToken } = useAuth();
  const { firstName, email } = getDecodedToken();

  const Messageto = ({
    messageTo, children, className,
  }) => {
    return <a className={className} target="blank" href={`https://wa.me/+549${PHONE_NUMBER}?text=${messageTo}`}>{children}</a>;
  };

  return (
    <div className="resume-fund">
      <div className="content-gral">
        <h2 className="title">
          <p>{t('movements_yield')}</p>
          <p className="buttons">
            <Messageto className="btn-gral blue" messageTo={`Buen día, soy ${firstName} (${email}) y quiero rescatar al fondo ... con $...`}>
              <img alt="" src={upload} />
              <span>
                {t('resume_btn1')}
              </span>
            </Messageto>
            <Messageto className="btn-gral" messageTo={`Buen día, soy ${firstName} (${email}) y quiero suscribirme al fondo ... con $...`}>
              <img alt="" src={download} />
              {t('resume_btn2')}
            </Messageto>
          </p>
        </h2>
        <div>
          <ResumeCard fund="altorendimiento" type="fund" />
          <Movements type={t('movements_yield')} />
        </div>
      </div>
    </div>
  );
}
