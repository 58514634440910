import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import copyIcon from '../../assets/img/copy.svg';
import { generateNewPassword, getUserById } from '../../services/users';

export default function UserRegistration() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordGenerated, setPasswordGenerated] = useState(false);
  const [error, setError] = useState(false);
  const [copiedClass, setCopiedClass] = useState('');
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    return getUserById(id)
      .then((data) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
      });
  }, []);
  function copyPass() {
    setCopiedClass('copied-successful');
    const content = document.getElementById('password');
    content.select();
    document.execCommand('copy');
  }
  function generatePassword() {
    return generateNewPassword(id)
      .then((data) => {
        if (data.error) {
          setError(true);
        } else {
          setPassword(data.newPassword);
          setError(false);
          setPasswordGenerated(true);
        }
      });
  }

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{t('restorePassword_error')}</p>
      );
    }
    return null;
  }

  function renderPassword() {
    if (passwordGenerated) {
      return (
        <div className="content-form">
          <div className="generated-password-box">
            <p>
              {t('restorePassword_subtitle1')}
              {` ${email} `}
              {t('restorePassword_subtitle2')}
            </p>
            <div className="copy-box">
              <input type="text" className="password" value={password} id="password" />
              <button type="button" title="Copiar" onClick={() => copyPass()}><img alt="copy" src={copyIcon} /></button>
              <span className={`copied ${copiedClass}`}>{t('restorePassword_copy')}</span>
            </div>
            <span className="msg">{t('restorePassword_msg')}</span>
            <a href="/" className={`btn-gral ${copiedClass}`}>{t('restorePassword_accept')}</a>
          </div>
        </div>
      );
    }
    return null;
  }
  function renderContent() {
    if (!passwordGenerated) {
      return (
        <div className="content-form">
          <h3>
            {t('restorePassword_confirm')}

          </h3>
          <h4>
            {t('restorePassword_name')}

          </h4>
          {`${firstName} ${lastName}`}
          <h4>
            Email:
          </h4>
          {`${email}`}
          {renderMsgError()}
          <button type="button" className="btn-gral" title="Generar" onClick={() => generatePassword()}>{t('restorePassword_btn')}</button>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="generate-password">
      <div className="content-gral">
        <h2 className="title-page">
          {t('restorePassword_title')}
        </h2>
        {renderContent()}
        {renderPassword()}
      </div>
    </div>
  );
}
