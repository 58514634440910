import Axios from 'axios';
import { getAuthorization } from './config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const sendMovements = (user, amount, type, fundType, description) => {
  return Axios({
    method: 'post',
    url: `${API_URL}api/create-movement`,
    data: {
      userId: user,
      amount,
      type,
      fundType,
      description,
    },
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    });
};

export const getMovements = (sort, skip, limit) => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/movements`,
    params: {
      sort,
      skip,
      limit,
    },
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    });
};

export const deleteMovements = (id) => {
  return Axios({
    method: 'delete',
    url: `${API_URL}api/movements/${id}`,
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export default { sendMovements, getMovements, deleteMovements };
