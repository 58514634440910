import Axios from 'axios';
import { getAuthorization } from './config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const sendBalance = (id, funds) => {
  return Axios({
    method: 'post',
    url: `${API_URL}api/editTotalFunds`,
    data: {
      fundsToEdit: funds,
      userId: id,
      type: 'rendimiento',
    },
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    });
};
export const getBalance = () => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/allbalance`,
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export default { sendBalance };
