import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/auth';

export default function PrivateRoute({ children, admin }) {
  const { isLoggedIn } = useAuth();
  const { isAdmin } = useAuth();
  if (!isLoggedIn()) {
    return <Navigate replace to="/login" />;
  }

  if (admin && !isAdmin()) {
    return <Navigate replace to="/resume" />;
  }

  return children;
}
