export const TRANSLATIONS_ES = {
  balance_error: 'Ocurrió un error al editar el valor',
  balance_success: 'Valor Editado Exitosamente',
  changePassword_btn: 'CAMBIAR CONTRASEÑA',
  changePassword_current: 'Ingrese Contraseña Actual',
  changePassword_error: 'Error al cambiar contraseña',
  changePassword_errorLength: 'La contraseña debe tener al menos 8 caracterer y no debe incluir espacios',
  changePassword_new: 'Ingrese nueva contraseña',
  changePassword_notMatch: 'Las contraseñas no coinciden',
  changePassword_placeholder1: 'Ingresar Contraseña Actual',
  changePassword_placeholder2: 'Ingresar Nueva Contraseña',
  changePassword_placeholder3: 'Repetir Nueva Contraseña',
  changePassword_repeat: 'Repita Nueva Contraseña',
  changePassword_title: 'Cambiar Contraseña',
  changePassword_wrong: 'contraseña actual incorrecta',
  editValue: 'Editar valores',
  forms_date: 'Fecha',
  forms_lastName: 'Apellido',
  forms_more: 'Ver más',
  forms_name: 'Nombre',
  forms_noResults: 'No se encontraron formularios',
  forms_title: 'Inscripciones',
  header_password: 'Cambiar Contraseña',
  header_session: 'Cerrar Sesión',
  home_disable: 'Deshabilitar Usuario',
  home_edit: 'Editar Valor',
  home_email: 'Correo',
  home_name: 'Nombre',
  home_lastName: 'Apellido',
  home_newMovement: 'Cargar Movimiento',
  home_noFound: 'No se encontraron usuarios',
  home_password: 'Generar Contraseña',
  home_title: 'Usuarios',
  home_total: 'Valor Total',
  idiom: 'ES ',
  load_more_btn: 'Cargar Más',
  loadBalance_actualValues: 'Valores Actuales',
  loadBalance_btn: 'CARGAR',
  loadBalance_editValues: 'Editar Valores',
  loadBalance_error: 'Error al cargar saldo',
  loadBalance_fixedIncome: 'Renta Fija',
  loadBalance_highYield: 'Alto Rendimiento',
  loadBalance_liquid: 'Líquido',
  loadBalance_placeholder: 'Ingresar Monto',
  loadBalance_title: 'Editar Valor Total',
  loadBalance_total: 'Valor Total Actual',
  loadBalance_totalFixedIncome: 'Valor Fondo Renta Fija',
  loadBalance_totalHighYield: 'Valor Fondo Alto Rendimiento',
  loadBalance_totalLiquidFunds: 'Valor Fondo Líquido',
  loadBalance_user: 'Usuario',
  loadMovement_amount: 'Monto',
  loadMovement_btn: 'CONFIRMAR',
  loadMovement_description: 'Descripción',
  loadMovement_error: 'Error al crear movimiento',
  loadMovement_option_type_1: 'Rescate',
  loadMovement_option_type_2: 'Depósito',
  loadMovement_option_type_3: 'Management Fee',
  loadMovement_option_type_4: 'Performance Fee',
  loadMovement_option1: 'Líquido',
  loadMovement_option2: 'Renta Fija',
  loadMovement_option3: 'Alto Rendimiento',
  loadMovement_optionTitle: 'Elegir una opción',
  loadMovement_placeholder: 'Ingresar Monto',
  loadMovement_title: 'Cargar Movimiento a',
  loadMovement_type_fund: 'Tipo de Fondo',
  loadMovement_type: 'Tipo de Movimiento',
  login_authError: 'Error email o contraseña son invalidos',
  login_btn: 'Iniciar',
  login_errorLogin: 'Error al ingresar',
  login_missingFields: 'Faltan Campos',
  login_password: 'Contraseña',
  login_title: 'Iniciar Sesión',
  movement_type_deposit: 'Depósito',
  movement_type_fee: 'Management Fee',
  movement_type_performance_fee: 'Performance Fee',
  movement_type_rescue: 'Rescate',
  movements_amount: 'Monto',
  movements_date: 'Fecha',
  movements_delete: 'Eliminar',
  movements_description: 'Descripción',
  movements_error: 'Error al traer movimientos',
  movements_errorToast: 'Error al eliminar movimiento',
  movements_fundType: 'Tipo de Fondo',
  movements_liquid: 'Líquido',
  movements_movementType: 'Tipo de Movimiento',
  movements_noFound: 'No se encontraron movimientos',
  movements_rent: 'Renta Fija',
  movements_success: 'Exito al borrar movimiento',
  movements_title: 'Movimientos',
  movements_user: 'Usuario',
  movements_userNotFound: 'Usuario no encontrado',
  movements_yield: 'Alto Rendimiento',
  nav_createUser: 'ALTA DE USUARIO',
  nav_funds: 'FONDOS',
  nav_inscriptions: 'INSCRIPCIONES',
  nav_liquid: 'CRIPTO LÍQUIDO',
  nav_movements: 'MOVIMIENTOS',
  nav_rent: 'RENTA FIJA',
  nav_resume: 'RESUMEN',
  nav_users: 'USUARIOS',
  nav_yield: 'ALTO RENDIMIENTO',
  restorePassword_accept: 'Aceptar',
  restorePassword_btn: 'CONFIRMAR',
  restorePassword_confirm: 'Confirme los datos.',
  restorePassword_copy: 'Copiado',
  restorePassword_error: 'Error al generar contraseña',
  restorePassword_msg: 'Recuerde copiar y guardar esta contraseña antes de salir',
  restorePassword_name: 'Nombre y apellido:',
  restorePassword_subtitle1: 'La contraseña generada para el usuario',
  restorePassword_subtitle2: 'es: ',
  restorePassword_title: 'Generar Nuevo Password',
  resume_6_month: 'Últimos 6 Meses',
  resume_btn1: 'RESCATAR',
  resume_btn2: 'SUSCRIBIR',
  resume_crypto: 'Cripto Líquido',
  resume_error: 'Error al obtener datos',
  resume_fixed: 'Renta Fija',
  resume_general: 'Resumen de Tenencias',
  resume_high: 'Alto Rendimiento',
  resume_holdings_summary: 'Resumen de Tenencias',
  resume_month: 'Último Mes',
  resume_profitability: 'Rentabilidad',
  resume_title: 'Resumen',
  resume_total: 'Valor Total',
  resume_year: 'Último año',
  saveMovement_error: 'Error al guardar movimiento',
  saveMovement_success: 'Movimiento guardado',
  totalBalance: 'Valor Acumulado Total',
  userRegistration_accept: 'Aceptar',
  userRegistration_btn: 'CONFIRMAR',
  userRegistration_copied: 'Copiado',
  userRegistration_error: 'Error al registrar usuario',
  userRegistration_lastName: 'Apellido',
  userRegistration_msg: 'Recuerde copiar y guardar esta contraseña antes de salir',
  userRegistration_name: 'Nombre',
  userRegistration_passwordGenerated1: 'La contraseña generada para el usuario',
  userRegistration_passwordGenerated2: 'es:',
  userRegistration_placeholderLastName: 'Ingresa el apellido',
  userRegistration_placeholderMail: 'nombre@ejemplo.com',
  userRegistration_placeholderName: 'Ingresa el nombre',
  userRegistration_title: 'Alta de Usuario',
  webFormsDetails_aboutQuestion1: 'Búsqueda Online',
  webFormsDetails_aboutQuestion2: 'Empleado de Patagonia',
  webFormsDetails_aboutQuestion3: 'Referencia Externa',
  webFormsDetails_aboutQuestion4: 'Conferencia / Evento',
  webFormsDetails_aboutQuestion5: 'Prensa',
  webFormsDetails_amountTitle: '¿Cuanto capital quieres invertir?',
  webFormsDetails_city: 'Ciudad',
  webFormsDetails_company: 'Nombre de Compañia',
  webFormsDetails_funds: '¿Como nos conociste?',
  webFormsDetails_interests: '¿En que fondos esta interesado?',
  webFormsDetails_interestsOption1: 'Cripto Líquido',
  webFormsDetails_interestsOption2: 'Renta Fija',
  webFormsDetails_interestsOption3: 'Alto Rendimiento',
  webFormsDetails_investorOption1: 'Asesor',
  webFormsDetails_investorOption2: 'Inversor Individual',
  webFormsDetails_investorOption3: 'Inversor Institucional',
  webFormsDetails_investorType: 'Tipo de inversión',
  webFormsDetails_lastname: 'Apellido',
  webFormsDetails_name: 'Nombre',
  webFormsDetails_phone: 'Número de teléfono',
  webFormsDetails_title: 'Formulario de',
  webFormsDetails_workTitle: 'Título de trabajo',
  coin_title: 'Cryptos',
};

export default TRANSLATIONS_ES;
