import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import btnAdd from '../../assets/img/plus.svg';
import btnGeneratePassword from '../../assets/img/generate_password.svg';
import userEnable from '../../assets/img/user_enable.svg';
import userDisable from '../../assets/img/user_disable.svg';
import { getUser, disableUser } from '../../services/users';
import Loader from '../../components/loader';
import btnSearchBold from '../../assets/img/search-icon.svg';
import UserDetailModal from '../../components/UserDetailModal';
import btnEdit from '../../assets/img/edit.svg';

export default function Home() {
  const pageQuant = 10;
  const [skip, setSkip] = useState(0);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    return getUser('-createdAt', skip, pageQuant)
      .then((data) => {
        if (data.length >= 0) {
          setUsers(users.concat(data));
          setLoading(false);
        }
        if (data.length < pageQuant) {
          setNoMoreResults(true);
        }
        if (data.error) {
          setLoading(false);
          console.log(data.error);
        }
      });
  }, [skip]);

  function loadMore() {
    setSkip(skip + pageQuant);
  }

  function renderDisableBtn(status) {
    if (status.disabled === true) {
      return <img alt="" src={userEnable} />;
    }
    return <img alt="" src={userDisable} />;
  }

  function disableUserFunc(userId) {
    setLoading(true);
    return disableUser(userId)
      .then((disableOperation) => {
        if (disableOperation) {
          return getUser('-createdAt', skip, pageQuant)
            .then((data) => {
              if (data.length > 0) {
                setUsers(data);
                setLoading(false);
              }
              if (data.error) {
                setLoading(false);
                console.log(data.error);
              }
            });
        }
        if (disableOperation.error) {
          setLoading(false);
          console.log(disableOperation.error);
        }
        return disableOperation;
      });
  }

  function openModal(userId) {
    document.getElementById(`id-${userId}`).style.display = 'flex';
  }

  function closeModal(userId) {
    document.getElementById(`id-${userId}`).style.display = 'none';
  }

  function renderTable() {
    const items = [];
    users.forEach((user) => {
      let sumFunds = 0;
      user.funds.forEach((fund) => {
        sumFunds += fund.balance;
      });
      items.push((
        <tr key={user._id}>
          <td>{`${user.firstName}`}</td>
          <td>{`${user.lastName}`}</td>
          <td>{user.email}</td>
          <td className="balance">
            <p className="balance-info">
              {`$ ${sumFunds / 100}`}
            </p>
            <button type="button" className="info-btn" id={user._id} onClick={() => openModal(user._id)}>
              <img alt="" src={btnSearchBold} />
            </button>
            {}
            <UserDetailModal
              closeModal={() => closeModal(user._id)}
              userName={user.firstName}
              userLastName={user.lastName}
              userEmail={user.email}
              userId={user._id}
              userFunds={user.funds}
              sumFunds={sumFunds}
            />
          </td>
          <td>
            <Link to={`/load-balance/${user._id}`}>
              <img alt="" src={btnEdit} />
            </Link>
          </td>
          <td>
            <Link to={`/load-movement/${user._id}`}>
              <img alt="" src={btnAdd} />
            </Link>
          </td>
          <td>
            <Link to={`/restore-password/${user._id}`}>
              <img alt="" src={btnGeneratePassword} />
            </Link>
          </td>
          <td className="centered">
            <button type="button" onClick={() => disableUserFunc(user._id)}>
              {renderDisableBtn(user)}
            </button>
          </td>
        </tr>
      ));
    });
    if (items.length > 0) {
      return items;
    }
    return (<h3>{t('home_noFound')}</h3>);
  }
  return (
    <div className="home-page">
      <div className="content-gral">
        <h2 className="title-page">{t('home_title')}</h2>
        <div className="table-container">
          <div className="scrolleable">
            {
              loading ? <Loader />
                : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>{t('home_name')}</th>
                          <th>{t('home_lastName')}</th>
                          <th>{t('home_email')}</th>
                          <th className="center">{t('home_total')}</th>
                          <th className="center">{t('home_edit')}</th>
                          <th className="small">{t('home_newMovement')}</th>
                          <th className="small">{t('home_password')}</th>
                          <th className="small">{t('home_disable')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderTable()}
                      </tbody>
                    </table>
                    {
                      !noMoreResults
                        ? <button type="button" className="btn-gral" onClick={loadMore}>{t('load_more_btn')}</button>
                        : null
                    }
                  </>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
