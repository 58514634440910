import Axios from 'axios';
import { getAuthorization } from './config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const getAll = (skip, limit) => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/investmentinscriptions?sort=name -createdAt`,
    params: {
      skip,
      limit,
    },
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export const getById = (id) => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/investmentinscriptions/${id}`,
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export default { getAll, getById };
