import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './styles.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import btnMoreDetails from '../../assets/img/details.svg';
import { getAll } from '../../services/investment-inscriptions';
import Loader from '../../components/loader';

export default function Forms() {
  const pageQuant = 5;
  const [error, setError] = useState(false);
  const [form, setForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    return getAll(skip, pageQuant)
      .then((data) => {
        if (data.length > 0) {
          setForm(form.concat(data));
          setError(false);
        }
        if (data.length < pageQuant) {
          setNoMoreResults(true);
        }
        setLoading(false);
        if (data.error) {
          setLoading(false);
          setError(true);
        }
      });
  }, [skip]);

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">
          Error
        </p>
      );
    }
    return null;
  }
  function loadMore() {
    setSkip(skip + pageQuant);
  }
  function renderTable() {
    const items = [];

    form.forEach((forms) => {
      items.push((
        <tr key={forms._id}>
          <td>
            {moment(forms.createdAt).format('DD/MM/YYYY - HH:mm')}
          </td>
          <td>
            {forms.firstName}
          </td>
          <td>{forms.lastName}</td>
          <td>{forms.email}</td>
          <td className="ver-mas">
            <Link to={`/inscriptions/${forms._id}`}>
              <img alt="" src={btnMoreDetails} />
              <span>
                {t('forms_more')}
              </span>
            </Link>
          </td>
        </tr>
      ));
    });
    if (items.length > 0) {
      return items;
    }
    return (<h3>{t('forms_noResults')}</h3>);
  }
  return (
    <div className="movements-page">
      <div className="content-gral">
        <h2 className="title-page">{t('forms_title')}</h2>
        {msgError()}

        <div className="table-container">
          <div className="scrolleable">
            {
              loading ? <Loader />
                : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>{t('forms_date')}</th>
                          <th>{t('forms_name')}</th>
                          <th>{t('forms_lastName')}</th>
                          <th>Email</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderTable()}
                      </tbody>
                    </table>
                    {
                      !noMoreResults
                        ? <button type="button" className="btn-gral" onClick={loadMore}>{t('load_more_btn')}</button>
                        : null
                    }
                  </>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
