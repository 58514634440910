import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loader from '../../components/loader';
import { getUserById } from '../../services/users';
import EditContainer from '../../components/edit-container';

export default function Resume() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    return getUserById(id)
      .then((data) => {
        setLoading(false);
        setFirstName(data.firstName);
        setLastName(data.lastName);
      });
  }, []);

  return (
    <div className="resume-page">
      <div className="content-gral">
        <h2 className="title">
          <p>{t('loadBalance_title')}</p>
        </h2>
        <p>
          {t('loadBalance_user')}
          <b>
            {' '}
            {` ${firstName} ${lastName}`}
          </b>
        </p>

        {
          loading ? <Loader />
            : (
              <div className="cards-funds">
                <EditContainer />

              </div>
            )
        }

      </div>
    </div>
  );
}
