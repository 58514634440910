import React, { useRef } from 'react';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/logo_footer.svg';
import { useAuth } from '../../hooks/auth';
import world from '../../assets/img/mundo.svg';
import dropDown from '../../assets/img/dropdown.svg';
import i18n from '../../translations/i18n';

export default function Nav({ activeMenu }) {
  const { isAdmin } = useAuth();
  const admin = isAdmin();
  const { t } = useTranslation();
  const list = useRef(null);
  const icon = useRef(null);

  function changeLanguage(language) {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }

  function showLinks() {
    if (list.current.className === 'resumeList oculto') {
      icon.current.className = 'up';
      list.current.className = 'resumeList';
    } else {
      icon.current.className = 'down';
      list.current.className = 'resumeList oculto';
    }
    return null;
  }

  return (
    <div className={`nav-component ${activeMenu}`}>
      <div>
        <div className="logo">
          <img alt="" src={logo} />
        </div>
        <ul>
          {!admin ? (
            <>
              <li>
                <NavLink end to="/resume">{t('nav_resume')}</NavLink>
              </li>
            </>
          ) : null}
          {admin ? (
            <>
              <li>
                <NavLink end to="/">{t('nav_users')}</NavLink>
              </li>
              <li>
                <NavLink end to="/user-registration">{t('nav_createUser')}</NavLink>
              </li>
              <li>
                <NavLink end to="/inscriptions">{t('nav_inscriptions')}</NavLink>
              </li>

            </>
          ) : (
            <li>
              <button type="button" className="nav-btn" onClick={() => showLinks()}>
                {t('nav_funds')}
                <img src={dropDown} alt="" ref={icon} />
              </button>
              <ul className="resumeList oculto" ref={list}>
                <li>
                  <NavLink end to="/resume-liquid">{t('nav_liquid')}</NavLink>
                </li>
                <li>
                  <NavLink end to="/resume-rent">{t('nav_rent')}</NavLink>
                </li>
                <li>
                  <NavLink end to="/resume-yield">{t('nav_yield')}</NavLink>
                </li>
              </ul>
            </li>
          )}
          <li>
            <NavLink end to="/movements">{t('nav_movements')}</NavLink>
          </li>
        </ul>
      </div>

      <div className="dropdown-cont">
        <img className="world-icon" alt="" src={world} />
        {t('idiom')}
        <img className="dropdown-icon" alt="" src={dropDown} />
        <div className="dropdown-list">
          <button onClick={() => changeLanguage('en')} type="button">EN</button>
          <button onClick={() => changeLanguage('es')} type="button">ES</button>
        </div>
      </div>
    </div>
  );
}
