import Axios from 'axios';
import { getAuthorization } from './config-request';

const API_URL = process.env.REACT_APP_API_URL;

export const getCoins = (name, price, symbol) => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/coins`,
    data: {
      name,
      price,
      symbol,
    },
    headers: getAuthorization(),
  })
    .then((response) => {
      return response.data;
    });
};

export default { getCoins };
