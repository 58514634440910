import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { sendMovements } from '../../services/movements';
import { getUserById } from '../../services/users';

export default function LoadMovement() {
  const [amount, setAmount] = useState();
  const [type, setType] = useState('');
  const [typeFund, setTypeFund] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    return getUserById(id)
      .then((data) => {
        setFirstName(data.firstName);
      });
  }, []);

  useEffect(() => {
    if (amount && type !== '' && typeFund !== '' && description !== '') {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [amount, type, typeFund, description]);
  useEffect(() => {
    if (amount) {
      setAmount(Math.abs(amount));
    }
  }, [type]);

  function newMovement() {
    return sendMovements(id, amount, type, typeFund, description)
      .then((data) => {
        if (data.error) {
          setError(true);
          toast.error(t('saveMovement_error'));
        } else {
          toast.success(t('saveMovement_success'));
          navigate('/');
        }
      })
      .catch(() => {
        setError(true);
        toast.error(t('saveMovement_error'));
      });
  }

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">{t('loadMovement_error')}</p>
      );
    }
    return null;
  }

  return (
    <div className="user-registration">
      <div className="content-gral">
        <h2 className="title-page">
          {t('loadMovement_title')}
          {` ${firstName}`}
        </h2>
        <div className="content-form">
          <div className="line-form">
            <span>{t('loadMovement_type')}</span>
            <select
              name=""
              id=""
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option
                selected
                disabled
              >
                {t('loadMovement_optionTitle')}
              </option>
              <option value="rescate">{t('loadMovement_option_type_1')}</option>
              <option value="deposito">{t('loadMovement_option_type_2')}</option>
              <option value="management fee">{t('loadMovement_option_type_3')}</option>
              <option value="performance fee">{t('loadMovement_option_type_4')}</option>
            </select>
          </div>
          <div className="line-form">
            <span>{t('loadMovement_type_fund')}</span>
            <select
              name=""
              id=""
              onChange={(e) => {
                setTypeFund(e.target.value);
              }}
            >
              <option
                selected
                disabled
              >
                {t('loadMovement_optionTitle')}
              </option>
              <option value="liquido">{t('loadMovement_option1')}</option>
              <option value="rentafija">{t('loadMovement_option2')}</option>
              <option value="altorendimiento">{t('loadMovement_option3')}</option>
            </select>
          </div>
          <div className="line-form">
            <span>{t('loadMovement_amount')}</span>
            <input
              type="number"
              value={(amount) ? amount / 100 : ''}
              placeholder={t('loadMovement_placeholder')}
              onChange={(e) => {
                setAmount(Math.abs(parseFloat(e.target.value) * 100));
              }}
            />
          </div>
          <div className="line-form">
            <span>{t('loadMovement_description')}</span>
            <textarea
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          {msgError()}
          <button type="button" className={`btn-gral ${!submitEnabled ? 'disabled' : ''}`} onClick={() => newMovement()}>{t('loadMovement_btn')}</button>
        </div>
      </div>
    </div>
  );
}
