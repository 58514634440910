import Axios from 'axios';
import { getAuthorization } from './config-request';

const API_URL = process.env.REACT_APP_API_URL;
export const signupUser = (email, firstName, lastName) => {
  return Axios({
    method: 'post',
    url: `${API_URL}api/auth/signup`,
    data: {
      email,
      firstName,
      lastName,
    },
    headers: getAuthorization(),
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export const getUser = (sort, skip, limit) => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/users`,
    params: {
      sort,
      skip,
      limit,
    },
    headers: getAuthorization(),
  }).then((resp) => {
    return resp.data;
  })
    .catch((err) => {
      return { error: err.response };
    });
};

export const disableUser = (userId) => {
  return Axios({
    method: 'delete',
    url: `${API_URL}api/disableUser/${userId}`,
    headers: getAuthorization(),
  }).then((resp) => {
    return resp.data;
  })
    .catch((err) => {
      return { error: err.response };
    });
};

export const getUserById = (id) => {
  return Axios({
    method: 'get',
    url: `${API_URL}api/users/${id}`,
    headers: getAuthorization(),
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export const generateNewPassword = (idUser) => {
  return Axios({
    method: 'post',
    url: `${API_URL}api/auth/restorepassword`,
    data: {
      idUser,
    },
    headers: getAuthorization(),
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};
export const changePassword = (currentPassword, password) => {
  return Axios({
    method: 'post',
    url: `${API_URL}api/auth/changepassword`,
    headers: getAuthorization(),
    data: {
      currentPassword,
      password,
    },
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return { error: err.response };
    });
};

export default {
  signupUser, getUser, getUserById, generateNewPassword, changePassword,
};
