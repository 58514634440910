import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthProvider from './hooks/auth';
import PrivateRoute from './private-route';
import Home from './pages/home';
import Login from './pages/login';
import NavBar from './components/nav-bar';
import UserRegistration from './pages/user-registration';
import LoadMovement from './pages/load-movement';
import LoadBalance from './pages/load-balance';
import Movements from './pages/movements';
import RestorePassword from './pages/restore-password';
import ChangePassword from './pages/change-password';
import WebForms from './pages/web-forms';
import DetailsForm from './pages/web-forms-details';
import Resume from './pages/resume';
import ResumeLiquid from './pages/resume-funds/liquid/index';
import ResumeRent from './pages/resume-funds/rent/index';
import ResumeYield from './pages/resume-funds/yield/index';
import './translations/i18n';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute admin><NavBar><Home /></NavBar></PrivateRoute>} />
            <Route path="/user-registration" element={<PrivateRoute admin><NavBar><UserRegistration /></NavBar></PrivateRoute>} />
            <Route path="/load-movement/:id" element={<PrivateRoute admin><NavBar><LoadMovement /></NavBar></PrivateRoute>} />
            <Route path="/load-balance/:id" element={<PrivateRoute admin><NavBar><LoadBalance /></NavBar></PrivateRoute>} />
            <Route path="/restore-password/:id" element={<PrivateRoute admin><NavBar><RestorePassword /></NavBar></PrivateRoute>} />
            <Route path="/movements" element={<PrivateRoute><NavBar><Movements /></NavBar></PrivateRoute>} />
            <Route path="/changepassword" element={<PrivateRoute><NavBar><ChangePassword /></NavBar></PrivateRoute>} />
            <Route path="/inscriptions" element={<PrivateRoute><NavBar><WebForms /></NavBar></PrivateRoute>} />
            <Route path="/inscriptions/:id" element={<PrivateRoute><NavBar><DetailsForm /></NavBar></PrivateRoute>} />
            <Route path="/resume" element={<PrivateRoute><NavBar><Resume /></NavBar></PrivateRoute>} />
            <Route path="/resume-liquid" element={<PrivateRoute><NavBar><ResumeLiquid /></NavBar></PrivateRoute>} />
            <Route path="/resume-rent" element={<PrivateRoute><NavBar><ResumeRent /></NavBar></PrivateRoute>} />
            <Route path="/resume-yield" element={<PrivateRoute><NavBar><ResumeYield /></NavBar></PrivateRoute>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
