export const TRANSLATIONS_EN = {
  balance_error: 'Failed to edit value',
  balance_success: 'Value successfully edited',
  changePassword_btn: 'CHANGE PASSWORD',
  changePassword_current: 'Insert current Password',
  changePassword_error: 'Error changing the password',
  changePassword_errorLength: 'The password must have at least 8 characters and must not include spaces',
  changePassword_new: 'Insert new Password',
  changePassword_notMatch: 'Passwords do not match',
  changePassword_placeholder1: 'Insert current Password',
  changePassword_placeholder2: 'Insert new Password',
  changePassword_placeholder3: 'Repeat new Password',
  changePassword_repeat: 'Repeat new Password',
  changePassword_title: 'Change Password',
  changePassword_wrong: 'Wrong Current Password',
  editValue: 'Edit values',
  forms_date: 'Date',
  forms_lastName: 'Last Name',
  forms_more: 'More',
  forms_name: 'First Name',
  forms_noResults: 'No results found',
  forms_title: 'Inscriptions',
  header_password: 'Change Password',
  header_session: 'Logout',
  home_disable: 'Disable User',
  home_edit: 'Edit Value',
  home_email: 'Email',
  home_name: 'First Name',
  home_lastName: 'Last Name',
  home_newMovement: 'Add Movement',
  home_noFound: 'No Users Found',
  home_password: 'Generate Password',
  home_title: 'Users',
  home_total: 'Total Value',
  idiom: 'EN ',
  load_more_btn: 'Load More',
  loadBalance_actualValues: 'Actual Values',
  loadBalance_btn: 'ADD',
  loadBalance_editValues: 'Edit Values',
  loadBalance_error: 'Failure loading balance',
  loadBalance_fixedIncome: 'Fixed Income',
  loadBalance_highYield: 'High Yield',
  loadBalance_liquid: 'Liquid',
  loadBalance_placeholder: 'Add amount',
  loadBalance_title: 'Edit Total Value',
  loadBalance_total: 'Total Value',
  loadBalance_totalFixedIncome: 'Fixed Income value',
  loadBalance_totalHighYield: 'High Yield value',
  loadBalance_totalLiquidFunds: 'Liquid Funds value',
  loadBalance_user: 'User',
  loadMovement_amount: 'Amount',
  loadMovement_btn: 'CONFIRM',
  loadMovement_description: 'Description',
  loadMovement_error: 'Error Creating movement',
  loadMovement_option_type_1: 'Redeem',
  loadMovement_option_type_2: 'Deposit',
  loadMovement_option_type_3: 'Management Fee',
  loadMovement_option_type_4: 'Performance Fee',
  loadMovement_option1: 'Liquid',
  loadMovement_option2: 'Fixed Income',
  loadMovement_option3: 'High Yield',
  loadMovement_optionTitle: 'Choose an option',
  loadMovement_placeholder: 'Add amount',
  loadMovement_title: 'Add Movement to',
  loadMovement_type_fund: 'Type of Fund',
  loadMovement_type: 'Type of Movement',
  login_authError: 'Error email or password are invalid',
  login_btn: 'Login',
  login_errorLogin: 'Error logging',
  login_missingFields: 'Missing Fields',
  login_password: 'Password',
  login_title: 'Log In',
  movement_type_deposit: 'Deposit',
  movement_type_fee: 'Management Fee',
  movement_type_performance_fee: 'Performance Fee',
  movement_type_rescue: 'Rescue',
  movements_amount: 'Amount',
  movements_date: 'Date',
  movements_delete: 'Delete',
  movements_description: 'Description',
  movements_error: 'Error finding movements',
  movements_errorToast: 'Failed to delete movement',
  movements_fundType: 'Fund type',
  movements_liquid: 'Liquid',
  movements_movement: 'Description',
  movements_movementType: 'Movement type',
  movements_noFound: 'No movements found',
  movements_rent: 'Fixed Income',
  movements_success: 'Movement Successfully deleted',
  movements_title: 'Movements',
  movements_user: 'User',
  movements_userNotFound: 'User not found',
  movements_yield: 'High Yield',
  nav_createUser: 'CREATE USERS',
  nav_funds: 'FUNDS',
  nav_inscriptions: 'INSCRIPTIONS',
  nav_liquid: 'LIQUID CRYPTO',
  nav_movements: 'MOVEMENTS',
  nav_rent: 'FIXED INCOME',
  nav_resume: 'HOME',
  nav_users: 'USERS',
  nav_yield: 'HIGH YIELD',
  restorePassword_accept: 'Accept',
  restorePassword_btn: 'CONFIRM',
  restorePassword_confirm: 'Confirm the data.',
  restorePassword_copy: 'Copied',
  restorePassword_error: 'Error generating password',
  restorePassword_msg: 'Remenber to copy and save this password before exiting',
  restorePassword_name: 'First Name and Lastname:',
  restorePassword_subtitle1: 'The password genererated for the user',
  restorePassword_subtitle2: 'is: ',
  restorePassword_title: 'Generate New Password',
  resume_6_month: 'Last 6 months',
  resume_btn1: 'REDEEM',
  resume_btn2: 'SUBSCRIBE',
  resume_crypto: 'Liquid Crypto',
  resume_error: 'Error getting data',
  resume_fixed: 'Fixed Income',
  resume_general: 'Holdings summary',
  resume_high: 'High Yield',
  resume_holdings_summary: 'Holdings summary',
  resume_month: 'Last month',
  resume_profitability: 'Yield',
  resume_title: 'Home',
  resume_total: 'Total Value',
  resume_year: 'Last year',
  saveMovement_error: 'Failed to save movement',
  saveMovement_success: 'Movement Saved',
  totalBalance: 'Total accumulated value',
  userRegistration_accept: 'Accept',
  userRegistration_btn: 'CONFIRM',
  userRegistration_copied: 'Copied',
  userRegistration_error: 'Error registering user',
  userRegistration_lastName: 'Last Name',
  userRegistration_msg: 'Remember to copy and save this password before exiting',
  userRegistration_name: 'First Name',
  userRegistration_passwordGenerated1: 'The password generated for the user',
  userRegistration_passwordGenerated2: 'is:',
  userRegistration_placeholderLastName: 'Insert last name',
  userRegistration_placeholderMail: 'name@example.com',
  userRegistration_placeholderName: 'Insert name',
  userRegistration_title: 'Create User',
  webFormsDetails_aboutQuestion1: 'Online Search',
  webFormsDetails_aboutQuestion2: 'Patagonia Employee',
  webFormsDetails_aboutQuestion3: 'External Reference',
  webFormsDetails_aboutQuestion4: 'Conference / Event',
  webFormsDetails_aboutQuestion5: 'Press',
  webFormsDetails_amountTitle: 'How much capital do you want to allocate?',
  webFormsDetails_city: 'City',
  webFormsDetails_company: 'Company name',
  webFormsDetails_funds: 'How did you meet us?',
  webFormsDetails_interests: 'In what funds are you interested?',
  webFormsDetails_interestsOption1: 'Liquid Crypto',
  webFormsDetails_interestsOption2: 'Fixed Income',
  webFormsDetails_interestsOption3: 'High Yield',
  webFormsDetails_investorOption1: 'Adviser',
  webFormsDetails_investorOption2: 'Individual Investor',
  webFormsDetails_investorOption3: 'Institutional Investor',
  webFormsDetails_investorType: 'Investment Type',
  webFormsDetails_lastname: 'Last Name',
  webFormsDetails_name: 'First Name',
  webFormsDetails_phone: 'Phone number',
  webFormsDetails_title: 'Inscription of',
  webFormsDetails_workTitle: 'Work Title',
  coin_title: 'Cryptos',
};

export default TRANSLATIONS_EN;
