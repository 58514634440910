import React, { useEffect, useState } from 'react';
import './styles.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import btnDelete from '../../assets/img/delete.svg';
import { getMovements, deleteMovements } from '../../services/movements';
import { useAuth } from '../../hooks/auth';
import { getBalance } from '../../services/balance';
import Loader from '../loader';

export default function Movements({ type }) {
  const pageQuant = 5;
  const [movements, setMovements] = useState([]);
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState('Error al traer movimientos');
  const [skip, setSkip] = useState(0);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const { isAdmin } = useAuth();
  const admin = isAdmin();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    return getMovements('-createdAt', skip, pageQuant)
      .then((data) => {
        if (data.length > 0) {
          setMovements(movements.concat(data));
        }
        if (data.length < pageQuant) {
          setNoMoreResults(true);
        }
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError(true);
      });
  }, [skip]);

  function removeMovement(id) {
    return deleteMovements(id)
      .then((data) => {
        if (data.error) {
          setError(true);
          setMessageError('Error al eliminar movimiento');
          toast.error(t('movements_errorToast'));
        } else {
          setError(false);
          const updateMovements = movements.filter((movement) => {
            return movement._id !== id;
          });
          setMovements(updateMovements);
          toast.success(t('movements_success'));
        }
      });
  }

  useEffect(() => {
    return getBalance()
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        }
      });
  }, []);

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">
          {messageError}
        </p>
      );
    }
    return null;
  }

  function loadMore() {
    setSkip(skip + pageQuant);
  }

  function renderMovementTypeText(movType) {
    if (movType === 'deposito') {
      return t('movement_type_deposit');
    }
    if (movType === 'rescate') {
      return t('movement_type_rescue');
    }
    if (movType === 'management fee') {
      return t('movement_type_fee');
    }
    if (movType === 'performance fee') {
      return t('movement_type_performance_fee');
    }
    return '';
  }

  function renderTable() {
    const items = [];
    const movementsFormat = movements.map((movement) => {
      let tipo = '';
      let usuario;
      let movementAmount;
      if (movement.fundType === 'liquido') {
        tipo = t('movements_liquid');
      }
      if (movement.fundType === 'rentafija') {
        tipo = t('movements_rent');
      }
      if (movement.fundType === 'altorendimiento') {
        tipo = t('movements_yield');
      }
      if (movement.user) {
        usuario = movement.user.email;
      } else {
        usuario = t('movements_userNotFound');
      }
      if (movement.amount >= 0) {
        movementAmount = `$${movement.amount / 100}`;
      } else {
        movementAmount = `- $${Math.abs(movement.amount / 100)}`;
      }
      return {
        id: movement._id,
        description: movement.description,
        createdAt: ` ${moment(movement.createdAt).format('DD/MM/YYYY - hh:mm')} hs`,
        user: usuario,
        fundType: tipo,
        type: movement.type,
        amount: movementAmount,
      };
    });

    movementsFormat.forEach((movement) => {
      if (movement.fundType === type) {
        items.push((
          <tr key={movement.id}>
            <td>
              {movement.createdAt}
            </td>
            {admin ? (
              <td>{movement.user}</td>
            ) : null}
            <td>{movement.description}</td>
            <td>{renderMovementTypeText(movement.type)}</td>
            <td>
              {movement.amount}
            </td>
            {admin ? (
              <td>
                <button
                  type="button"
                  onClick={() => removeMovement(movement.id)}
                >
                  <img alt="" src={btnDelete} />
                </button>
              </td>
            ) : null}
          </tr>
        ));
      }
    });
    if (items.length > 0) {
      return items;
    }
    return (<h3>{t('movements_noFound')}</h3>);
  }
  return (
    <div className="movements-page">
      <div>
        <h2 className="title">{t('movements_title')}</h2>
        {msgError()}

        <div className="table-container">
          <div className="scrolleable">
            {
              loading ? <Loader />
                : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>{t('movements_date')}</th>
                          {admin ? (
                            <th>{t('movements_user')}</th>
                          ) : null}
                          <th>{t('movements_description')}</th>
                          <th>{t('movements_movementType')}</th>
                          <th>{t('movements_amount')}</th>
                          {admin ? (
                            <th>{t('movements_delete')}</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {renderTable()}
                      </tbody>
                    </table>
                    {
                      !noMoreResults
                        ? <button type="button" className="btn-gral" onClick={loadMore}>{t('load_more_btn')}</button>
                        : null
                    }
                  </>
                )
                }
          </div>
        </div>
      </div>
    </div>
  );
}
