import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getById } from '../../services/investment-inscriptions';

export default function DetailsForm() {
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [town, setTown] = useState('');
  const [company, setCompany] = useState('');
  const [job, setJob] = useState('');
  const [phone, setPhone] = useState('');
  const [inverterType, setInverterType] = useState('');
  const [fundInterest, setFundInterest] = useState('');
  const [amountFund, setAmountFund] = useState('');
  const [howMeet, setHowMeet] = useState('');
  const { t } = useTranslation();

  function translateInvestor(investor) {
    if (investor === 'Asesor') {
      return t('webFormsDetails_investorOption1');
    }
    if (investor === 'Inversor Individual') {
      return t('webFormsDetails_investorOption2');
    }
    if (investor === 'Inversor Institucional') {
      return t('webFormsDetails_investorOption3');
    }
    return 'error';
  }

  function translateInterests(interests) {
    if (interests === 'Cripto Líquido') {
      return t('webFormsDetails_interestsOption1');
    }
    if (interests === 'Renta Fija') {
      return t('webFormsDetails_interestsOption2');
    }
    if (interests === 'Alto Rendimiento') {
      return t('webFormsDetails_interestsOption3');
    }
    return 'error asdasd';
  }

  function translateHowMeet(howMeetText) {
    if (howMeetText === 'Búsqueda Online') {
      return t('webFormsDetails_aboutQuestion1');
    }
    if (howMeetText === 'Empleado de Patagonia') {
      return t('webFormsDetails_aboutQuestion2');
    }
    if (howMeetText === 'Referencia Externa') {
      return t('webFormsDetails_aboutQuestion3');
    }
    if (howMeetText === 'Conferencia / Evento') {
      return t('webFormsDetails_aboutQuestion4');
    }
    if (howMeetText === 'Prensa') {
      return t('webFormsDetails_aboutQuestion5');
    }
    return 'error';
  }

  useEffect(() => {
    return getById(id)
      .then((data) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setTown(data.town);
        setCompany(data.company);
        setJob(data.job);
        setPhone(data.phone);
        setInverterType(translateInvestor(data.inverterType));
        setFundInterest(translateInterests(data.fundInterest));
        setAmountFund(data.amountFund);
        setHowMeet(translateHowMeet(data.howMeet));
        setError(false);
        if (data.error) {
          setError(true);
        }
      });
  }, []);

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">
          Error
        </p>
      );
    }
    return null;
  }
  return (
    <div className="movements-page">
      <div className="content-gral">
        <h2 className="title-page">
          {t('webFormsDetails_title')}
          {` ${firstName} ${lastName}`}
        </h2>
        {msgError()}
        <form>
          <div className="line-form double">
            <div className="line-double">
              <span>{t('webFormsDetails_name')}</span>
              <input
                type="text"
                value={firstName}
                readOnly
              />
            </div>
            <div className="line-double">
              <span>{t('webFormsDetails_lastname')}</span>
              <input
                type="text"
                value={lastName}
                readOnly
              />
            </div>
          </div>
          <div className="line-form double">
            <div className="line-double">
              <span>Email</span>
              <input
                type="text"
                value={email}
                readOnly
              />
            </div>
            <div className="line-double">
              <span>{t('webFormsDetails_city')}</span>
              <input
                type="text"
                value={town}
                readOnly
              />
            </div>
          </div>
          <div className="line-form double">
            <div className="line-double">
              <span>{t('webFormsDetails_company')}</span>
              <input
                type="text"
                value={company}
                readOnly
              />
            </div>
            <div className="line-double">
              <span>{t('webFormsDetails_workTitle')}</span>
              <input
                type="text"
                value={job}
                readOnly
              />
            </div>
          </div>
          <div className="line-form">
            <span>{t('webFormsDetails_phone')}</span>
            <input
              type="text"
              value={phone}
              readOnly
            />
          </div>
          <div className="line-form">
            <span>{t('webFormsDetails_investorType')}</span>
            <input
              type="text"
              value={inverterType}
              readOnly
            />
          </div>
          <div className="line-form">
            <span>{t('webFormsDetails_interests')}</span>
            <input
              type="text"
              value={fundInterest}
              readOnly
            />
          </div>
          <div className="line-form">
            <span>{t('webFormsDetails_amountTitle')}</span>
            <input
              type="text"
              value={amountFund}
              readOnly
            />
          </div>
          <div className="line-form">
            <span>{t('webFormsDetails_funds')}</span>
            <input
              type="text"
              value={howMeet}
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
}
