import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { ReactComponent as Arrow } from '../../assets/img/arrow-up.svg';

export default function FundCard({ fund, fundType }) {
  const [t] = useTranslation();

  function fundBalance() {
    if (fundType === 'rentafija' && fund && fund.finalAmount) {
      return ((fund.finalAmount / 100));
    }
    if (fundType === 'liquido' && fund && fund.finalAmount) {
      return ((fund.finalAmount / 100));
    }
    if (fundType === 'altorendimiento' && fund && fund.finalAmount) {
      return ((fund.finalAmount / 100));
    }
    return null;
  }

  function nameBalance() {
    if (fundType === 'rentafija') {
      return (t('resume_fixed'));
    }
    if (fundType === 'liquido') {
      return (t('resume_crypto'));
    }
    if (fundType === 'altorendimiento') {
      return (t('resume_high'));
    }
    return null;
  }

  function arrow(data) {
    return <Arrow className={data < 0 ? 'arrow low' : 'arrow high'} />;
  }
  function performance() {
    return (
      <>
        <div>
          <p>{t('resume_month')}</p>
          <h2>
            {fund && fund.performance.ultimoMes ? `${fund.performance.ultimoMes}%` : '-'}
            {fund && fund.performance.ultimoMes ? arrow(fund.performance.ultimoMes) : ''}
          </h2>
        </div>
        <div>
          <p>{t('resume_6_month')}</p>
          <h2>
            {fund && fund.performance.ultimosSeisMeses ? `${fund.performance.ultimosSeisMeses}%` : '-'}
            {fund && fund.performance.ultimosSeisMeses ? arrow(fund.performance.ultimosSeisMeses) : ''}
          </h2>
        </div>
        <div>
          <p>{t('resume_year')}</p>
          <h2>
            {fund && fund.performance.ultimosDoceMeses ? `${fund.performance.ultimosDoceMeses}%` : '-'}
            {fund && fund.performance.ultimosDoceMeses ? arrow(fund.performance.ultimosDoceMeses) : ''}
          </h2>
        </div>
      </>
    );
  }

  return (
    <div className="container-fund">
      <h2 className="title">{nameBalance()}</h2>
      <section className="fund-stats">
        <div className="value blues">
          <h3 className="title-price">{t('resume_total')}</h3>
          <h2 className="price-fund">
            { (typeof fundBalance() === 'number') ? `$${fundBalance()}` : '-'}
          </h2>
        </div>
        <div className="datos">
          <h3>{t('resume_profitability')}</h3>
          <section>
            {performance()}
          </section>
        </div>
      </section>
    </div>
  );
}
