import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { changePassword } from '../../services/users';

export default function changePasswordOfUser() {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState(t('changePassword_errorLength'));
  const navigate = useNavigate();

  useEffect(() => {
    if (
      currentPassword.length >= 8 && !currentPassword.includes(' ')
      && password.length >= 8 && !password.includes(' ')
      && passwordRepeat.length >= 8 && !passwordRepeat.includes(' ')
    ) {
      setSubmitEnabled(true);
      setError(false);
    } else {
      setSubmitEnabled(false);
      setError(true);
      setMessageError(t('changePassword_errorLength'));
    }
  }, [currentPassword, password, passwordRepeat]);

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">
          { messageError }
        </p>
      );
    }
    return null;
  }

  function changeCurrentPassword() {
    if (password === passwordRepeat) {
      return changePassword(currentPassword, password)
        .then((resp) => {
          if (resp.error && resp.error.data.code === 'current_password_invalid') {
            setError(true);
            setMessageError(t('changePassword_wrong'));
          } else if (resp.error) {
            setError(true);
            setMessageError(t('changePassword_error'));
          } else {
            setError(false);
            setMessageError('');
            navigate('/');
          }
        });
    }
    setError(true);
    setMessageError(t('changePassword_notMatch'));
    return null;
  }

  return (
    <div className="user-registration">
      <div className="content-gral">
        <h2 className="title-page">
          {t('changePassword_title')}
        </h2>
        <div className="content-form">
          <div className="line-form">
            <h4>{t('changePassword_current')}</h4>
            <input
              type="password"
              placeholder={t('changePassword_placeholder1')}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>
          <div className="line-form">
            <h4>{t('changePassword_new')}</h4>
            <input
              type="password"
              placeholder={t('changePassword_placeholder2')}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="line-form">
            <h4>{t('changePassword_repeat')}</h4>
            <input
              type="password"
              placeholder={t('changePassword_placeholder3')}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
              }}
            />
          </div>
          {msgError()}
          <button type="button" className={`btn-gral ${!submitEnabled ? 'disabled' : ''}`} onClick={() => changeCurrentPassword()}>{t('changePassword_btn')}</button>
        </div>
      </div>
    </div>
  );
}
