import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { getBalance } from '../../services/balance';
import { ReactComponent as Arrow } from '../../assets/img/arrow-up.svg';
import Loader from '../loader';

export default function ResumeCard({ fund, type }) {
  const [performance, setPerformance] = useState();
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  function calculoGeneral(data, mes) {
    if (mes === 'ultimoMes') {
      const arrayMontos = [];
      if (data.rentafija.performance.ultimoMes) {
        arrayMontos.push(data.rentafija.performance.ultimoMes);
      }
      if (data.liquido.performance.ultimoMes) {
        arrayMontos.push(data.liquido.performance.ultimoMes);
      }
      if (data.altorendimiento.performance.ultimoMes) {
        arrayMontos.push(data.altorendimiento.performance.ultimoMes);
      }
      let sum = 0;
      arrayMontos.forEach((element) => {
        sum += element;
      });
      const avg = sum / arrayMontos.length;
      return avg;
    }
    if (mes === 'ultimosSeisMeses') {
      const arrayMontos = [];
      if (data.rentafija.performance.ultimosSeisMeses) {
        arrayMontos.push(data.rentafija.performance.ultimosSeisMeses);
      }
      if (data.liquido.performance.ultimosSeisMeses) {
        arrayMontos.push(data.liquido.performance.ultimosSeisMeses);
      }
      if (data.altorendimiento.performance.ultimosSeisMeses) {
        arrayMontos.push(data.altorendimiento.performance.ultimosSeisMeses);
      }
      let sum = 0;
      arrayMontos.forEach((element) => {
        sum += element;
      });
      const avg = sum / arrayMontos.length;
      return avg;
    }
    if (mes === 'ultimosDoceMeses') {
      const arrayMontos = [];
      if (data.rentafija.performance.ultimosDoceMeses) {
        arrayMontos.push(data.rentafija.performance.ultimosDoceMeses);
      }
      if (data.liquido.performance.ultimosDoceMeses) {
        arrayMontos.push(data.liquido.performance.ultimosDoceMeses);
      }
      if (data.altorendimiento.performance.ultimosDoceMeses) {
        arrayMontos.push(data.altorendimiento.performance.ultimosDoceMeses);
      }
      let sum = 0;
      arrayMontos.forEach((element) => {
        sum += element;
      });
      const avg = sum / arrayMontos.length;
      return avg;
    }

    if (mes === 'finalAmount') {
      const arrayMontos = [];
      if (data.rentafija.finalAmount) {
        arrayMontos.push(data.rentafija.finalAmount);
      }
      if (data.liquido.finalAmount) {
        arrayMontos.push(data.liquido.finalAmount);
      }
      if (data.altorendimiento.finalAmount) {
        arrayMontos.push(data.altorendimiento.finalAmount);
      }
      let sum = 0;
      arrayMontos.forEach((element) => {
        sum += element;
      });
      return sum;
    }
    return null;
  }
  useEffect(() => {
    setLoading(true);
    setPerformance({});
    return getBalance()
      .then((data) => {
        if (fund === 'rentafija') {
          setPerformance(data.rentafija);
        }
        if (fund === 'liquido') {
          setPerformance(data.liquido);
        }
        if (fund === 'altorendimiento') {
          setPerformance(data.altorendimiento);
        }
        if (fund === 'general') {
          const performanceGeneral = {
            finalAmount: calculoGeneral(data, 'finalAmount'),
            performance: {
              ultimoMes: calculoGeneral(data, 'ultimoMes'),
              ultimosSeisMeses: calculoGeneral(data, 'ultimosSeisMeses'),
              ultimosDoceMeses: calculoGeneral(data, 'ultimosDoceMeses'),
            },
          };
          setPerformance(performanceGeneral);
        }
        setLoading(false);
        return null;
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  function fundBalance() {
    if (fund === 'rentafija' && performance && performance.finalAmount) {
      return ((performance.finalAmount / 100));
    }
    if (fund === 'liquido' && performance && performance.finalAmount) {
      return ((performance.finalAmount / 100));
    }
    if (fund === 'altorendimiento' && performance && performance.finalAmount) {
      return ((performance.finalAmount / 100));
    }
    if (fund === 'general' && performance && performance.finalAmount) {
      return ((performance.finalAmount / 100));
    }
    return null;
  }

  function arrow(data) {
    return <Arrow className={data < 0 ? 'arrow low' : 'arrow high'} />;
  }

  function typeBalance() {
    if (type === 'fund') {
      return t('resume_holdings_summary');
    }
    if (type === 'general') {
      return t('resume_general');
    }
    return null;
  }

  return (
    <div className="container-fundss">
      {
              loading ? <Loader />
                : (
                  <>
                    <h2>{typeBalance()}</h2>
                    <section className="fund">
                      <div className="blue">
                        <h3>{t('resume_total')}</h3>
                        <h2>
                          { (typeof fundBalance() === 'number') ? `$${fundBalance()}` : '-'}
                        </h2>
                      </div>
                      <div className="stats">
                        <h3>{t('resume_profitability')}</h3>
                        <section>
                          <div>
                            <p>{t('resume_month')}</p>
                            <h2>
                              {performance && performance.performance.ultimoMes ? `${performance.performance.ultimoMes}%` : '-'}
                              {performance && performance.performance.ultimoMes ? arrow(performance.performance.ultimoMes) : ''}
                            </h2>
                          </div>
                          <div>
                            <p>{t('resume_6_month')}</p>
                            <h2>
                              {performance && performance.performance.ultimosSeisMeses ? `${performance.performance.ultimosSeisMeses}%` : '-'}
                              {performance && performance.performance.ultimosSeisMeses ? arrow(performance.performance.ultimosSeisMeses) : ''}
                            </h2>
                          </div>
                          <div>
                            <p>{t('resume_year')}</p>
                            <h2>
                              {performance && performance.performance.ultimosDoceMeses ? `${performance.performance.ultimosDoceMeses}%` : '-'}
                              {performance && performance.performance.ultimosDoceMeses ? arrow(performance.performance.ultimosDoceMeses) : ''}
                            </h2>
                          </div>
                        </section>
                      </div>
                    </section>
                  </>
                )
                }
    </div>
  );
}
