import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/logo_footer.svg';
import fondo from '../../assets/img/fondo.png';
import world from '../../assets/img/mundo.svg';
import dropDown from '../../assets/img/dropdown.svg';
import { login as loginService } from '../../services/authentication';
import i18n from '../../translations/i18n';

export default function Login() {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t('login_errorLogin'));
  const navigate = useNavigate();

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  function changeLanguage(language) {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }

  function errorStyle() {
    if (error) {
      return ('error');
    }
    return ('');
  }
  function login(e) {
    e.preventDefault();
    return loginService(email, password)
      .then((resp) => {
        if (resp && resp.error && resp.error.status !== 200) {
          setError(true);
          if (resp.error.data.code === 'missing_params') {
            setErrorMessage(t('login_missingFields'));
          }
          if (resp.error.data.code === 'authentication_failed') {
            setErrorMessage(t('login_authError'));
          }
          return null;
        }
        setEmail('');
        setPassword('');
        return navigate('/');
      });
  }
  return (
    <div className="login-page">
      <div className="login-side">
        <form className={`login-box ${errorStyle()}`} onSubmit={login}>
          <div className="brand">{t('login_title')}</div>
          <div className="line-form">
            <span>E-mail</span>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="line-form">
            <span>{t('login_password')}</span>
            <input
              type="password"
              autoComplete="new-password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          {renderMsgError()}
          <button type="submit" className="btn-gral">{t('login_btn')}</button>
        </form>
      </div>
      <div className="lateral">
        <img className="back" alt="" src={fondo} />
        <img className="brand" alt="" src={logo} />
        <div className="dropdown-cont login">
          <img className="world-icon" alt="" src={world} />
          {t('idiom')}
          <img className="dropdown-icon" alt="" src={dropDown} />
          <div className="dropdown-list">
            <button onClick={() => changeLanguage('en')} type="button">EN</button>
            <button onClick={() => changeLanguage('es')} type="button">ES</button>
          </div>
        </div>
      </div>
    </div>
  );
}
