import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import './styles.scss';
import signOut from '../../assets/img/sign_out.svg';
import editPassword from '../../assets/img/edit_white.svg';
import menuBtn from '../../assets/img/menu.svg';
import dropDown from '../../assets/img/dropdown.svg';
import Nav from '../nav';

export default function Header() {
  const [menuMobile, setMenuMobile] = useState('');
  const { unsetToken, getDecodedToken } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const fullname = `${getDecodedToken().firstName} ${getDecodedToken().lastName}`;
  function logout() {
    unsetToken();
    navigate('/login');
  }

  function changePassword() {
    navigate('/changepassword');
  }
  return (
    <div className="header-component">
      <button type="button" className="mobile-btn" onClick={() => setMenuMobile('active')}>
        <img alt="" src={menuBtn} />
      </button>
      <button type="button" className={`mobile-background ${menuMobile}`} onClick={() => setMenuMobile(' ')}> </button>
      <div className="user-name">
        {fullname}
        <img className="dropdown-icon" alt="" src={dropDown} />
        <div className="dropdown-list">
          <button type="button" className="" onClick={() => changePassword()}>
            {t('header_password')}
            <img alt="" src={editPassword} />
          </button>
          <button type="button" className="" onClick={() => logout()}>
            {t('header_session')}
            {' '}
            <img alt="" src={signOut} />
          </button>
        </div>
      </div>
      <Nav activeMenu={menuMobile} />
    </div>
  );
}
