import React from 'react';
import './styles.scss';

export default function CoinCard({ name, price, symbol }) {
  return (
    <div className="coin-card">
      <h3>
        {`${name} - `}
        <span>{symbol}</span>
      </h3>
      <h2>
        {`$${price}`}
      </h2>
    </div>
  );
}
