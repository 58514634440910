import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import iconClose from '../../assets/img/xmark-solid.svg';

export default function UserDetailModal({
  isOpen,
  closeModal,
  userName,
  userEmail,
  userId,
  userFunds,
  userLastName,
  sumFunds,
}) {
  const { t } = useTranslation();

  function eventHandler(e) {
    e.stopPropagation();
  }

  function fundsUser(type) {
    const fundsBalance = [];
    userFunds.forEach((fund) => {
      const fundd = fund.balance ? fund.balance : '0';
      if (fund.type === type) {
        fundsBalance.push(
          fundd,
        );
      }
    });
    return fundsBalance;
  }

  return (
    <div
      id={`id-${userId}`}
      className={`modal ${isOpen ? 'modal-open' : ''}`}
      onClick={closeModal}
      role="button"
      tabIndex="0"
      aria-hidden="true"
    >
      <div className="modal-dialog" onClick={eventHandler} aria-hidden="true">
        <h2>{`${t('loadBalance_user')}: ${userName} ${userLastName}`}</h2>
        <h4>{`${t('home_email')}: ${userEmail}`}</h4>
        <button type="button" onClick={closeModal}>
          <img src={iconClose} alt="" />
        </button>
        <div className="stats">
          <div className="stat">
            <h3>{t('resume_crypto')}</h3>
            <p>
              {`${t('resume_total')}:`}
              <span>{`$${fundsUser('liquido') / 100}`}</span>
            </p>
          </div>
          <div className="stat">
            <h3>{t('resume_fixed')}</h3>
            <p>
              {`${t('resume_total')}:`}
              <span>{`$${fundsUser('rentafija') / 100}`}</span>
            </p>
          </div>
          <div className="stat">
            <h3>{t('resume_high')}</h3>
            <p>
              {`${t('resume_total')}:`}
              <span>{`$${fundsUser('altorendimiento') / 100}`}</span>
            </p>
          </div>
        </div>
        <div>
          <h3 className="total">
            <span>{`${t('resume_total')}:`}</span>
            {` $${sumFunds / 100}`}
          </h3>
        </div>
      </div>
    </div>
  );
}
