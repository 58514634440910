import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import { toast } from 'react-toastify';
import { sendBalance } from '../../services/balance';
import { getUserById } from '../../services/users';

export default function EditContainer() {
  const [balance, setBalance] = useState('');
  const [liquido, setLiquido] = useState();
  const [rentaFija, setRentaFija] = useState();
  const [altoRendimiento, setAltoRendimiento] = useState();
  const [newLiquido, setNewLiquido] = useState();
  const [newRentaFija, setNewRentaFija] = useState();
  const [newAltoRendimiento, setNewAltoRendimiento] = useState();
  const [newBalance, setNewBalance] = useState('');
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    return getUserById(id)
      .then((data) => {
        let balanceAllFunds = 0;
        data.funds.forEach((dataFund) => {
          balanceAllFunds += dataFund.balance;
        });
        setBalance(balanceAllFunds);
        if (data.funds.length > 0) {
          data.funds.forEach((fund) => {
            switch (fund.type) {
              case 'liquido':
                setLiquido(fund.balance);
                setNewLiquido(fund.balance);
                break;
              case 'rentafija':
                setRentaFija(fund.balance);
                setNewRentaFija(fund.balance);
                break;
              case 'altorendimiento':
                setAltoRendimiento(fund.balance);
                setNewAltoRendimiento(fund.balance);
                break;
              default:
                setLiquido(0);
                setRentaFija(0);
                setAltoRendimiento(0);
                break;
            }
          });
        } else {
          setLiquido(0);
          setRentaFija(0);
          setAltoRendimiento(0);
        }
      });
  }, []);

  useEffect(() => {
    setNewLiquido(Math.abs(newLiquido || 0));
    setNewRentaFija(Math.abs(newRentaFija || 0));
    setNewAltoRendimiento(Math.abs(newAltoRendimiento || 0));
    setNewBalance(newLiquido + newRentaFija + newAltoRendimiento);
  }, [newLiquido, newRentaFija, newAltoRendimiento]);

  function updateBalance() {
    const funds = [{
      type: 'liquido',
      amount: newLiquido,
    },
    {
      type: 'rentafija',
      amount: newRentaFija,
    },
    {
      type: 'altorendimiento',
      amount: newAltoRendimiento,
    }];
    return sendBalance(id, funds)
      .then((data) => {
        if (data.error) {
          setError(true);
          toast.error(t('balance_error'));
        } else {
          toast.success(t('balance_success'));
          navigate('/');
        }
      });
  }

  function msgError() {
    if (error) {
      return (
        <p className="error-txt">d</p>
      );
    }
    return null;
  }

  return (
    <div className="container-funds">

      <h2>{t('loadBalance_actualValues')}</h2>
      <section className="fund">
        <div className="stats">
          <section>
            <div>
              <p>{t('loadBalance_totalLiquidFunds')}</p>
              {(liquido) ? <h3>{`$ ${liquido / 100}`}</h3> : '-'}
            </div>
            <div>
              <p>{t('loadBalance_totalFixedIncome')}</p>
              {(rentaFija) ? <h3>{`$ ${rentaFija / 100}`}</h3> : '-'}
            </div>
            <div>
              <p>{t('loadBalance_totalHighYield')}</p>
              {(altoRendimiento) ? <h3>{`$ ${altoRendimiento / 100}`}</h3> : '-'}
            </div>
            <div className="blue">
              <p>{t('totalBalance')}</p>
              {(balance) ? <h3>{`$ ${balance / 100}`}</h3> : '-' }
            </div>
          </section>
          <hr />

        </div>

      </section>
      <h2>{t('loadBalance_editValues')}</h2>
      <section className="fund">
        <div className="stats">
          <section>
            <div>
              <p>{t('loadBalance_totalLiquidFunds')}</p>
              {newLiquido >= 0 ? (
                <input
                  value={newLiquido / 100}
                  type="number"
                  step="0.01"
                  placeholder={t('loadBalance_placeholder')}
                  onChange={(e) => {
                    setNewLiquido(Math.abs(Math.round(parseFloat(e.target.value) * 100)));
                  }}
                />
              ) : <></> }
            </div>
            <div>
              <p>{t('loadBalance_totalFixedIncome')}</p>
              {newRentaFija >= 0 ? (
                <input
                  value={newRentaFija / 100}
                  type="number"
                  step="0.01"
                  placeholder={t('loadBalance_placeholder')}
                  onChange={(e) => {
                    setNewRentaFija(Math.abs(Math.round(parseFloat(e.target.value) * 100)));
                  }}
                />
              ) : <></>}
            </div>
            <div>
              <p>{t('loadBalance_totalHighYield')}</p>
              {newAltoRendimiento >= 0 ? (
                <input
                  value={newAltoRendimiento / 100}
                  type="number"
                  step="0.01"
                  placeholder={t('loadBalance_placeholder')}
                  onChange={(e) => {
                    setNewAltoRendimiento(Math.abs(Math.round(parseFloat(e.target.value) * 100)));
                  }}
                />
              ) : <></>}
            </div>
            <div className="blue">
              <p>{t('totalBalance')}</p>
              <h3>{`$ ${(newBalance / 100) ? newBalance / 100 : '-'}`}</h3>
            </div>
          </section>
        </div>
      </section>
      {msgError()}
      <div className="but">
        <button type="button" className="btn-gral" onClick={() => updateBalance()}>{t('loadBalance_btn')}</button>
      </div>
    </div>
  );
}
