import React from 'react';
import './styles.scss';

export default function Footer() {
  return (
    <div className="footer-component">
      © Copyright - Patagonia Capital 2022
    </div>
  );
}
